import React, { Fragment } from "react";
import { useIntl } from "gatsby-plugin-intl";
import moment from "moment";
import MobileDatePicker from "@material-ui/lab/MobileDatePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import enLocale from "date-fns/locale/en-US";
import zhLocale from "date-fns/locale/zh-TW";

import * as constants from "../../app.constant";
import calendarIcon from "../../assets/images/calendar-icon.svg";

const DateInputField = ({ label, name, formik, info, maxDate, minDate }) => {
    const intl = useIntl();
    const messages = intl.messages;
    const currentSelectedLocale = intl.locale;

    const onHandleLocale = () => {
        if (currentSelectedLocale === "zh") {
            return zhLocale;
        } else {
            return enLocale;
        }
    };

    const onDateSelected = (value) => {
        var date = value ? moment(value).format(constants.DATE_FORMAT) : "";
        formik.setFieldValue(name, date);
    };

    const isInvalidClass = () => {
        return formik.touched[name] && formik.errors[name] ? "is-invalid" : "";
    };

    return (
        <div className="form-group">
            <label className="form-group__label" htmlFor={name}>
                {label}
            </label>

            <div className="form-group__field-wrapper form-group__field-wrapper--datepicker">
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={onHandleLocale()}>
                    <MobileDatePicker
                        label={label}
                        value={formik.getFieldProps(name).value}
                        openTo="day"
                        maxDate={maxDate}
                        minDate={minDate}
                        showToolbar={false}
                        onChange={onDateSelected}
                        cancelText={messages["datepicker.cancelBtnText"]}
                        okText={messages["datepicker.okBtnText"]}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                            <Fragment>
                                <input
                                    ref={inputRef}
                                    {...inputProps}
                                    {...formik.getFieldProps(name)}
                                    className={`form-group__form-control form-control ${isInvalidClass()}`}
                                    id={name}
                                />

                                <img
                                    className="form-group__field-trailing-icon img-fluid"
                                    src={calendarIcon}
                                    alt="calendar"
                                    {...inputProps}
                                />
                            </Fragment>
                        )}
                    />
                </LocalizationProvider>

                {formik.touched[name] && formik.errors[name] ? (
                    <div className="form-group__error invalid-feedback">{formik.errors[name]}</div>
                ) : null}

                {info && <small className="form-group__info form-text">{info}</small>}
            </div>
        </div>
    );
};

export default DateInputField;
